import { styled } from '@mui/system'
import { Box, Grid as MuiGrid } from '@mui/material'
import Typography from '../../atoms/typography'
import Button from '../../molecules/button'

const shouldForwardProp = (prop) => !['reverse', 'hasSiblings', 'aboveTheFold'].includes(prop)

const ImageColumn = styled('div')`
	width: 100%;
	order: 2;
	${({ theme }) => theme.breakpoints.up('sm')} {
		order: ${({ reverse }) => (reverse ? '1' : '2')};
	}
`

const TextColumn = styled('div')`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding: var(--spacing-5) var(--spacing);
	${({ theme }) => theme.breakpoints.up('sm')} {
		padding: 0 167px;
	}
	> .description {
		font-size: var(--font-size-body1);
	}
`

const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	margin-bottom: 22px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		margin-bottom: 32px;
	}
`

const Container = styled(Box, {
	shouldForwardProp,
})`
	display: flex;
	align-items: center;
	flex-direction: column;
	> ${ImageColumn} {
		order: 1;
		height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		.MuiLink-root {
			height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		}
		${({ theme }) => theme.breakpoints.up('sm')} {
			order: ${({ reverse }) => (reverse ? '2' : '1')};
		}
	}
	> ${TextColumn} {
		order: 2;
		${({ theme }) => theme.breakpoints.up('sm')} {
			order: ${({ reverse }) => (reverse ? '1' : '2')};
		}
	}
	${({ theme }) => theme.breakpoints.up('sm')} {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings }) => (hasSiblings ? '40px' : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
		margin-top: ${({ hasSiblings }) => (hasSiblings ? '68px' : '0')};
	}
`

const Title = styled(Typography)`
	font-size: var(--font-size-h1);
	font-family: var(--font-alternative-bold);
	letter-spacing: var(--letter-spacing);
	line-height: calc(var(--spacing) * 2.75);
	margin-bottom: calc(var(--spacing) * 1.5);
	${({ theme }) => theme.breakpoints.up('md')} {
		line-height: var(--spacing-5);
		margin-bottom: var(--spacing-2);
	}
`

const Description = styled(Typography)``

const ButtonStyled = styled(Button)``

export { Container, ImageColumn, TextColumn, CtaContainer, ButtonStyled, Description, Title, Subtitle }
